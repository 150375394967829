#fxtrading-box {
  height: 600px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#fxtrading-line {
  height: 800px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#fxaccount-pop {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
#extention-forex-cap {
  margin-bottom: 10px;
  margin-top: 10px;
}
#forex-leverage-numberbox {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
}
