#responsiveBox div {
  font-size: 11px;
  padding-top: 10px;
  text-align: center;
}
.rb-item {
  height: 100%;
}
#cap_item {
  margin-left: 10px;
  margin-top: 15px;
}
#hm-btn-section {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}
#hm-btn-section-1 {
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}
#hm-btn-section-11 {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}
#hm-btn-section-2 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 175px;
}
#adminUsersGrid {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  height: 390px;
}
#adminVacationGrid {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  height: 200px;
}
#hm-btn-adminButton {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
}
#closebutton {
  margin-left: auto;
  margin-right: 0;
  margin-top: 30px;
  padding: 10px;
}
#closebutton-2 {
  margin-left: auto;
  margin-right: 0;
  margin-top: 30px;
}
#savebutton {
  margin-right: 10px;
}
#savebutton-left {
  margin-left: 10px;
}
#savebutton-right {
  margin-right: 10px;
}
#companiesGrid {
  margin-left: 60px;
  width: 250px;
  height: 200px;
}
#cases-cal-grid {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  height: 146px;
}
#casesNotesGridpopup {
  margin: 10px;
  height: 580px;
}
#case-popup {
  margin: 10px;
}
#dash-rev-chart {
  height: 600px;
}
#dash-area-01 {
  margin-top: 20px;
  margin-left: 0px;
}
#dash-area-02 {
  margin-top: 50px;
  margin-left: 50px;
  margin-left: 10px;
}
#dash-client-chart {
  height: 600px;
}
#dash-area-product {
  margin-top: 50px;
  margin-left: 50px;
  margin-left: 10px;
}
#dash-product-chart {
  height: 600px;
}
#dash-area-services {
  margin-top: 50px;
  margin-left: 50px;
  margin-left: 10px;
}
#dash-services-chart {
  height: 600px;
}
#dash-area-partner {
  margin-top: 50px;
  margin-left: 50px;
  margin-left: 10px;
}
#dash-partner-chart {
  height: 600px;
}
#dash-area-provider {
  margin-top: 50px;
  margin-left: 50px;
  margin-left: 10px;
}
#dash-provider-chart {
  height: 600px;
}
#dash-area-clientsinsignup {
  margin-top: 50px;
  margin-left: 50px;
  margin-left: 10px;
}
#dash-clientsinsignup-chart {
  height: 600px;
}
#dash-notes-news-grid {
  margin-left: 30px;
  width: 360px;
  height: 400px;
}
#dash-notes-details {
  margin-top: 20px;
}
#admin-dash-open-btn {
  margin-bottom: 20px;
  margin-left: 20px;
}
#closebutton-2-10{
  margin-left: auto;
  margin-right: 10px;
  margin-top: 30px;
}
#closebutton-2-20{
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 30px;
  
}