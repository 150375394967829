#inquiry-bottom-line {
  margin-bottom: 5px;
}
#client-close-button {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#client-base-box {
  height: 380px;
}
#client-data-name {
  margin-left: 10px;
}
#client-product-box {
  height: 320px;
  margin-right: 10px;
  margin-left: 20px;
}
#client-access-box {
  margin-right: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-top: 10px;
  height: 440px;
}
#client-users-box {
  margin-right: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-top: 10px;
  height: 440px;
}
#risk-matrix-result-line {
  margin-top: 20px;
}
#client-users-transactions-box {
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 600px;
}
#Client-notes-box {
  margin: 10px;
}
#client-comms-notes-grid {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
#client-comms-inquirydata-grid {
  margin-top: 20px;
}
#client-comms-box {
  height: 815px;
}
#client-reg-prod-box {
  margin-right: 20px;
  margin-bottom: 20x;
  margin-left: 20px;
  margin-top: 10px;
}
#client-comms-documents-grid {
  margin-top: 20px;
}
#client-ForexTrades-box {
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 800px;
}