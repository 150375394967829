#servproviderGrid {
  margin-left: 10px;
  margin-right: 10px;
  height: 340px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#SystemServiceFeaturesGrid {
  width: 95%;
  height: 300px;
}
#bankaccount-prop-button {
  margin-top: 20px;
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: auto;
}
#bankaccount-close-button {
  margin-right: 10px;
}
#bankaccount-form-edit {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
  height: 650px;
}
#bankaccount-edit-tab {
  margin-bottom: 10px;
  margin-left: 10px;
}
