#serviceprovider-data-box {
    height: 500px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
#serviceprovider-data-line {
    height: 600px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }

  #service-prov-data-countries-grid {
    margin-left: 20px;
  }