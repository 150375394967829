#rep-transaction-Grid {
  margin-left: 10px;
  margin-right: 10px;
  height: 350px;
}
#provider-clients-signup-label {
  margin-left: 10px;
}
#provider-client-Notes-Grid {
  margin: 10px;
  height: 300px;
}
#pop-savebutton-right {
  margin-right: 0px;
}
#approval-settings-popup-grid {
  width: 370px;
}
#rep-transactions-notes-grid {
  margin-right: 10px;
  margin-top: 10px;
  height: 320px;
}
