#responsiveBox div {
  font-size: 11px;
  padding-top: 10px;
  text-align: center;
}
.rb-item {
  height: 100%;
}
#casesNotesGrid {
  margin: 10px;
  height: 340px;
}
#newsGrid {
  margin: 10px;
  height: 670px;
}
#hm-btn-section {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}
#hm-btn-section-1 {
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}
#hm-btn-section-11 {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}
#hm-btn-section-0-1 {
  margin-right: 10px;
}
#hm-btn-section-0-11 {
  margin-top: 5px;
  margin-right: 10px;
}
#hm-btn-section-2 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 175px;
}
#adminUsersGrid {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  height: 390px;
}
#adminVacationGrid {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  height: 200px;
}
#hm-btn-adminButton {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
}
#cmpny-select {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 5px;
}
#cases-news-grid {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}
#savebutton {
  margin-right: 10px;
}
#closebutton {
  margin-left: auto;
  margin-right: 0;
  margin-top: 30px;
  padding: 10px;
}
#savebutton-left {
  margin-left: 10px;
}
#savebutton-right {
  margin-right: 10px;
}
#companiesGrid {
  margin-left: 60px;
  width: 250px;
  height: 200px;
}
#cases-cal-grid {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  height: 146px;
}
#casesNotesGridpopup {
  margin: 10px;
  height: 580px;
}
#rendercell {
  vertical-align: initial;
}
#operations-notes-tab {
  margin: 10px;
}
#notes-to-pop {
  margin-right: 10px;
}
#partner-portal-selectbox {
  margin-top: 10px;
}
#ops-cases-notes-grid {
  margin-left: 10px;
  margin-top: 10px;
  height: 380px;
}
#ops-activity-section {
  text-align: center;
}
#notes-to-subj-cont {
  margin-top: 20px;
}
#notes-to-closebutton {
  margin-left: auto;
  margin-right: 0;
  margin-top: 0px;
  padding: 10px;
}

.operation-page-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}

.operation-page-content-container {
  min-height: 100%;
  display: flex;
  flex: 1;
}
#op-cases-Grid {
  margin-left: 20px;
  margin-right: 10px;
}
#ops-client-search-edit {
  margin-left: 20px;
}