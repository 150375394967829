@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}

.single-card {

  .dx-card {
    width: 440px !important;
    padding: 20px !important;

    .wide-form{
      width: 100% !important;
    }
  }


}