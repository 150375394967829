#partnerGrid {
  margin-left: 10px;
  margin-right: 10px;
  height: 200px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#partner-edit-address-form-edit {
  margin-right: 20px;
  margin-left: 30px;
  margin-top: 10px;
}
#hm-btn-section-3 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
}
#rouing-system-services-Grid-2 {
  width: 300px;
}
#rouing-system-services-feature-Grid {
  width: 700px;
}
#partner-edit-page-tab {
  margin-bottom: 20px;
  margin-left: 10px;
}
#partner-editPage-button {
  margin-left: auto;
  margin-right: 10px;
}
#partner-tou-doc-Grid {
  height: 740px;
  margin-bottom: 30px;
}
#partner-edit-tou-buttons {
  margin-left: 10px;
}
