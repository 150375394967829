#admindash-data-box {
    height: 300px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
#admindash-data-line {
    height: 400px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }