#routingsGrid {
  margin-left: 10px;
  margin-right: 10px;
  height: 340px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#product-services-edit-grid {
  margin-left: 30px;
  margin-top: 0px;
  height: 250px;
  width: 40%;
}
#margin-services-edit-box {
  margin-top: 20px;
}
#product-system-servicesGrid {
  width: 95%;
  height: 300px;
}
