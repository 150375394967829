.thread-messages-wrapper {
  .message-list {
    pointer-events: all;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(191, 191, 191, 0.7);
      box-shadow: 0 0 0 1px transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: rgba(249, 249, 253, 0.78);
    }
  }

  .message-date {
    margin-bottom: 8px;
  }

  .message-wrapper {
    box-shadow: 0 0 10px 1px #f7f7f7;
    padding: 1rem;
    border-radius: 1rem;
    display: inline-block;
    white-space: break-spaces;
  }

  .spp-user {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .message-wrapper {
      background-color: #a8ddfb;
    }
  }

  .client {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;

    .message-wrapper {
      align-self: flex-start;
      background-color: #ebebeb;
    }
  }

  .message-wrapper {
    max-width: 100%;
    word-break: break-word;

    .message-files {
      display: flex;
      justify-content: end;
      flex-wrap: wrap;

      .file-item {
        margin: 0 5px;
        box-shadow: none;
        background-color: transparent;

        .dx-button-content {
          padding: 0;

          .dx-icon {
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 25px;
          }
        }

        .dx-button-text {
          color: #0d6efd;
          text-decoration: underline;
        }
      }
    }
  }

  .message-text-input {
    line-height: 20px;
    height: 60px;
    width: 100%;
  }
}
