#partnerGrid {
  margin-left: 10px;
  margin-right: 10px;
  height: 200px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#partner-edit-address-form-edit {
  margin-right: 20px;
  margin-left: 30px;
  margin-top: 10px;
}
#hm-btn-section-3 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
}
#rouing-system-services-Grid-2 {
  width: 300px;
}
#rouing-system-services-feature-Grid {
  width: 700px;
}
#tab-product-edit-page {
  margin-bottom: 20px;
  margin-left: 10px;
}
#partner-editPage-button {
  margin-left: auto;
  margin-right: 10px;
}
#partner-customer-product-edit {
  margin-right: 50px;
  margin-left: 22px;
}
#partner-customer-services-edit-box {
  margin-top: 30px;
}
#partner-product-services-edit-grid {
  margin-left: 50px;
  height: 300px;
  width: 500px;
}
#partner-product-services-pricing-grid {
  margin-left: 20px;
  height: 300px;
}
#partner-product-edit-cost-rates-risk-grid {
  height: 480px;
  width: 1000px;
}
#partner-cost-entries-form-edit-base {
  margin-right: 20px;
  margin-left: 5px;
  height: 600px;
}
#partner-edit-tou-doc-Grid {
  margin-top: 10px;
  margin-left: 20px;
}
#center-info {
  margin-left: 30px;
  margin-bottom: 50px;
}
