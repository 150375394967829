#responsiveBox div {
  font-size: 11px;
  padding-top: 10px;
  text-align: center;
}
.rb-item {
  height: 100%;
}
#casesGrid {
  margin-right: 10px;
  margin-left: 20px;
}
#casesNotesGrid {
  margin-top: 10px;
  margin-right: 20px;
  height: 340px;
}
#newsGrid {
  margin: 10px;
  height: 670px;
}
#hm-btn-section {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}
#hm-btn-section-1 {
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}
#hm-btn-section-11 {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}
#hm-btn-section-2 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 175px;
}
#adminUsersGrid {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  height: 390px;
}
#adminVacationGrid {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  height: 200px;
}
#hm-btn-adminButton {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
}
#cmpny-select {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 5px;
}
#cases-news-grid {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
  height: 750px;
}
#homeNotesTab {
  margin-left: 10px;
}
#savebutton {
  margin-right: 10px;
}
#deletebutton {
  margin-right: 10px;
}
#closebutton {
  margin-left: auto;
  margin-right: 0;
  margin-top: 30px;
  padding: 10px;
}
#savebutton-left {
  margin-left: 10px;
}
#companiesGrid {
  margin-left: 60px;
  width: 250px;
  height: 200px;
}
#cases-cal-grid {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  height: 146px;
}
#casesNotesGridpopup {
  margin: 10px;
  height: 580px;
}
