.approve-document-request {
  .attached-files {
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;

    .document-detail {
      padding: 5px;
      text-align: center;
    }

    .dx-icon {
      margin-bottom: 2px;
      font-size: 20px;
    }
  }

  .dx-icon-exportpdf {
    color: red;
  }

  .dx-icon-doc {
    color: blue;
  }
}

