#global-defs-box-line1-left {
  height: 500px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#global-defs-box-line1-right {
  height: 500px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#global-defs-box-line2 {
  height: 500px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#global-defs-region-grid {
  margin-right: 20px;
  margin-top: 20px;
}
#global-defs-tab-countries {
  margin-right: 30px;
  margin-top: 20px;
}
#global-defs-box-line3 {
  height: 500px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#global-defs-box-line4 {
  height: 500px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#global-defs-box-line5 {
  height: 600px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#global-defs-sys-sect-grid {
  margin-top: 20px;
}
#global-defs-features-grid {
  margin-top: 20px;
}
#global-defs-lookup-types-grid {
  margin-right: 20px;
}
#global-defs-lp-companytab-box {
  width: 60%;
}
#global-defs-box-line8 {
  height: 840px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#global-defs-mail-template-form {
  margin-top: 30px;
}
#portal-pop-sections-area {
  margin-left: 0px;
  margin-top: 30px;
}
#portal-pop-extension-grid {
  margin-left: 70px;
  margin-top: 10px;
}
#companytab-button {
  margin-left: 10px;
}
#lookup-manage-line-1 {
  margin-bottom: 10px;
}
#global-auto-grids {
  margin-bottom: 20px;
}
#global-auto-btn-section {
    margin: auto;
    padding-left: 40px;
  }
  #global-auto-btn-vert-dist {
    margin-top: 10px;
  }
