#mailing-send-button-container {
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
   height: 100%;
}

.mailing-label {
   width: 100px !important;
}

.mailing-container-mt-auto {
   margin-top: auto !important;
}

.column-nowrap {
   white-space: nowrap;
}

.recipient-item-button .dx-button {
   border-radius: 0 !important;
   height: 35px;
}

.align-left {
   text-align: left !important;
}

.mt-auto {
   margin-top: auto !important;
}

.pop-message{
   border: 1px solid #e0e0e0;
   margin-bottom: 10px;
   min-height: 130px;
   padding-left: 5px;
}

.button-wrapper{
   flex-direction: row;
}

.warning-text{
   font-size: 14px;
}

.dx-overlay-content .dx-popup-content {
   padding: 8px;
}

.dx-popup-content{
   #template-form{
      #closebutton-select{
         margin: 0 auto;
         text-align: center;
         margin-top: 25px;
      }
   }
}

.dx-texteditor-container{
   .dx-texteditor-input-container{
      textarea{
         border-top: 1px solid #e0e0e0;
         border-left: 1px solid #e0e0e0;
         border-right: 1px solid #e0e0e0;
      }
   }
}


#email-form{
   position: relative;
      #paste-src-content{
         position: absolute;
         right: 21px;
         margin-top: 27px;
         vertical-align: middle;
         line-height: 17px;
         text-transform: uppercase;
         font-weight: 500;
         letter-spacing: .04em;
         padding: 3px 1px 0 3px;
         margin-left: 10px;
         float: right;
      }
}

.select-template-scroll{
   overflow-y: auto;
}

.buttons-thread-wrapper{
   display: flex;
    align-items: center;
    flex-direction: row;
    width: 62%;
}

.title-thread{
   width: 482px;
   display: block;
   margin-top: 10px;
   border: 1px solid #e0e0e0;
}

.uploader{
   padding: 10px 0px 0px;
   margin-left: -10px;
   margin-top: 9px;
}