#MatrixEntryGrid {
  height: 300px;
}

#Matrix-Feature-Value-Grid {
  margin-top: 10px;
  height: 340px;
  width: 440px;
}

#Matrix-Data-Entry-Grid {
  height: 542px;
}

#Matrix-Data-Feature-Grid {
  height: 438px;
}

#Model-Grid {
  height: 200px;
}

#ai-form {
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

#ai-form-sub {
  margin-left: 30px;
}

#matrix-btn-1 {
  margin-right: 10px;
}

#matrix-btn-11 {
  margin-top: 10px;
  margin-right: 10px;
}
#matrix-btn-2 {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 175px;
}
