#services-data-box {
  height: 500px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#services-data-line-500 {
  height: 500px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#services-data-line-600 {
  height: 600px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
