.grid-compreqdoc-wrapper {
  display: grid;
  grid-template-columns: 50px repeat(5, 1fr) 50px;
  grid-template-rows: 40px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-height: 75vh;
}

.grid-compreqdoc-row-1-5 {
  grid-area: 1 / 1 / 2 / 6;
}

.grid-compreqdoc-row-6-6 {
  grid-area: 1 / 6 / 2 / 7;
  justify-self: end;
}

.grid-compreqdoc-row-7 {
  grid-area: 1 / 7 / 2 / 8;
}

.grid-compreqdoc-col-2-row-1 {
  grid-area: 2 / 1 / 3 / 2;
}

.grid-compreqdoc-col-2-row-2-6 {
  grid-area: 2 / 2 / 3 / 7;
}

.grid-compreqdoc-col-2-row-7 {
  grid-area: 2 / 7 / 3 / 8;
}

#btn-compReq {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  #compbutton {
    display: flex;
    gap: 10px;
  }
}

#req-client-doc-Grid {
  margin-top: 20px;
  margin-right: 16px;
}

.btn-action {

  &-accept {

    .dx-icon {
      color: #FFF;
    }

    margin-right: 5px;
    background-color: #5CB85C;

    &:hover {

      .dx-icon {
        color: #000;
      }

      background-color: #5CB85F;
    }

  }

  &-download {

    .dx-icon {
      color: #FFF;
    }

    margin-right: 5px;
    background-color: #337AB7;

    &:hover {

      .dx-icon {
        color: #000;
      }

      background-color: #337AB7;
    }

  }

  &-danger {

    .dx-icon {
      color: #FFF;
    }

    background-color: #D9534F;

    &:hover {

      .dx-icon {
        color: #000;
      }

      background-color: #D9534F;
    }

  }
}

.btn-add-document{
  color: #FFF;
  background-color: #5CB85F;
}