#servproviderGrid {
  margin-left: 10px;
  margin-right: 10px;
  height: 300px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#SystemServiceFeaturesGrid {
  width: 95%;
  height: 300px;
}
#services-edit-button {
  margin-top: 20px;
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: auto;
}
#services-edit_close-button {
  margin-right: 10px;
}
#partner-service-edit-featureGrid {
  margin: 10px;
}
#service-edit-feature-Buttons {
  margin-left: 10px;
  margin-top: 20px;
}
#service-cost-rates-header-grid {
  margin-left: 30px;
  margin-right: 10px;
  height: 120px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#service-cost-rates-entries {
  margin-left: 20px;
}
#service-cost-entries-area {
  margin-top: 20px;
}
#service-cost-entries-form-edit {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 30px;
  height: 60px;
}
#cost-rates-edit-tab {
  margin-left: 10px;
  margin-top: 20px;
}
#service-cost-rates-risk-grid {
  margin-left: 10px;
}
#service-cost-rates-entries-grid-service {
  height: 500px;
}
#service-cost-rates-countries-grid-service {
  height: 160px;
}
#partner-service-edit-cost-rates-risk-grid {
  height: 470px;
}
#serviceprovider-edit-form-edit {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
}
#partner-ervice-sell-rates-btn {
  margin-top: 20px;
}
