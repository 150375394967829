#routings-grid {
   margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}
#routing-notes-grid {
  margin-right: 10px;
  margin-top: 10px;
  height: 320px;
}
#routing-notes-btn {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
}
#routing-notes-btn-2 {
  margin-top: 10px;
  margin-right: 10px;
}
#rouing-services-tab-grid {
  height: 300px;
}
#rouing-products-tab-grid {
  height: 300px;
}
