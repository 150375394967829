#network-data-box {
    height: 500px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
#network-data-line {
    height: 600px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
#network-data-save-button {
    margin-top: 20px;
    margin-right: 0;
    margin-left: auto;
  }
#network-data-assoc-services {
    margin-top: 10px;
  }
#assoc-btn-1 {
    margin-top: 5px;
  }
#assoc-btn-2 {
    margin-top: 5px;
    margin-left: 5px;
  }