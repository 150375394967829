#partner-grid {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#form-edit {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#hm-btn-section-3 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
}
#rouing-system-services-Grid-2 {
  width: 300px;
}
#rouing-system-services-feature-Grid {
  width: 700px;
}
#partner-service-features {
  margin-top: 80px;
}
#partner-notes-grid {
  margin-right: 10px;
  height: 280px;
}
#partner-notes-btn {
  margin-right: 10px;
  margin-bottom: 0px;
}
#partner-notes-btn-2 {
  margin-top: 10px;
  margin-right: 10px;
}
#partner-services-tab-grid {
  margin-top: 10px;
  margin-right: 10px;
  height: 460px;
}
#partner-products-tab-grid {
  margin-top: 10px;
  height: 460px;
}
#partner-clients-signup-tab-grid {
  margin-top: 10px;
  margin-right: 10px;
  height: 460px;
}
#partner-transactions-tab-grid {
  margin-top: 10px;
  margin-right: 10px;
  height: 460px;
}
